<!--  -->
<template>
  <div class="Navigation">
    <div class="Navigation_content fixation">
      <div class="Navigation_content_img">
        <div class="logo">
          <img :src="logoImg" alt="" />
        </div>
        <div class="more" @click="moreNav">
          <img :src="moreImg" alt="" />
        </div>
      </div>

      <van-popup
        v-model="isShow"
        position="right"
        :style="{ width: '70%', height: '100%', marginTop: '0.635rem' }"
        :overlay="false"
      >
        <div class="Nav_content">
          <router-link v-for="item in menus" :key="item.text" :to="item.url">
            <div>
              <img
                :src="item.url == currentPath ? item.active : item.img"
                alt=""
              />
              <span class="NavText">{{ item.text }}</span>
            </div>
          </router-link>
        </div>
      </van-popup>

      <div class="Navigation_content_menu c">
        <router-link to="/" class="link">网站首页</router-link>
        <router-link to="/product" class="link">产品系列</router-link>
        <router-link to="/journalism" :style="{color:journalism}" class="link">{{ $globalVar.menu3 }}</router-link>
        <router-link to="/dialogue" class="link">用户留言</router-link class="link">
        <router-link to="/about" class="link">关于我们</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["spreadProduct","spreadJournalism"],
  data() {
    return {
      product:"",
      journalism:"",
      isShow: false,
      logoImg: require("@/assets/img/h5/logo.png"),
      moreImg: require("@/assets/img/h5/Nav/menu.png"),
      currentPath: "",
      menus: [
        {
          text: "网站首页",
          url: "/",
          img: require("@/assets/img/h5/Nav/home.png"),
          active: require("@/assets/img/h5/Nav/home2.png"),
        },
        {
          text: "产品分类",
          url: "/product",
          img: require("@/assets/img/h5/Nav/Product.png"),
          active: require("@/assets/img/h5/Nav/Product2.png"),
        },
        {
          text: this.$globalVar.menu3,
          url: "/journalism",
          img: require("@/assets/img/h5/Nav/PopularScience.png"),
          active: require("@/assets/img/h5/Nav/PopularScience2.png"),
        },
        {
          text: "用户留言",
          url: "/dialogue",
          img: require("@/assets/img/h5/Nav/LeaveMessage.png"),
          active: require("@/assets/img/h5/Nav/LeaveMessage2.png"),
        },
        {
          text: "联系我们",
          url: "/about",
          img: require("@/assets/img/h5/Nav/ContactUs.png"),
          active: require("@/assets/img/h5/Nav/ContactUs2.png"),
        },
      ],
    };
  },
  methods: {
    moreNav() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        this.moreImg = require("@/assets/img/h5/Nav/close.png");
      } else {
        this.moreImg = require("@/assets/img/h5/Nav/menu.png");
      }
    },
    getPath() {
      this.$nextTick(() => {
        if (this.spreadProduct !== "") {
          this.product=this.spreadProduct
        }
        if (this.spreadJournalism !== "") {
          this.journalism=this.spreadJournalism
        }
      });
    },
  },
  mounted() {
    this.getPath();

    if (window.screen.width >= 320 && window.screen.width <= 750) {
      this.logoImg = require("@/assets/img/h5/logo.png");
    }
    if (window.screen.width >= 751 && window.screen.width <= 1024) {
      this.logoImg = require("@/assets/img/logo.png");
    }
    if (window.screen.width >= 1024) {
      this.logoImg = require("@/assets/img/logo.png");
    }
    this.currentPath = this.$router.history.current.path;
  },
};
</script>
<style lang='less' scoped>
@media (min-width: 1024px) and (max-width: 1279px) {
  .Navigation {
    height: 100%;
    width: 100%;
    text-align: center;
    .Navigation_content {
      margin: 0 auto;
      display: flex;
      height: 110px;
      line-height: 110px;
      .Navigation_content_img {
        width: 14%;
        height: 100%;
        .logo {
          img {
            width: 112px;
            height: 50px;
          }
        }
        .more {
          display: none;
        }
      }
      .Navigation_content_menu {
        flex: 1;
        font-size: 25px;
        display: block;
        font-family: cursive !important;
        a {
          color: rgb(0, 0, 0);
          padding: 0 2.83%;
        }
        .link:hover {
          color: #CC9966 !important;
        }
        .router-link-exact-active {
          color: #CC9966 !important;
        }
      }
    }
  }
}
@media all and(min-width: 1900px) {
  .fixation {
    width: 65%;
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1900px) {
  .fixation {
    width: 65%;
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1800px) {
  .fixation {
    width: 68%;
    .About_right {
      img {
        width: 110%;
      }
    }
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1700px) {
  .fixation {
    width: 72%;
  }
  .About_right {
    img {
      width: 105%;
    }
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1600px) {
  .fixation {
    width: 76%;
    .About_right {
      img {
        width: 100%;
      }
    }
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1500px) {
  .fixation {
    width: 80%;
  }
  .fixationProduct {
    width: 85%;
  }
}
@media all and(max-width: 1400px) {
  .fixation {
    width: 84%;
    .About_right {
      img {
        width: 90%;
      }
    }
  }
}
@media all and(max-width: 1300px) {
  .fixation {
    width: 88%;

    .About_right {
      img {
        width: 80%;
      }
    }
  }
}
@media all and(max-width: 1200px) {
  .fixation {
    width: 92%;
  }
}
@media (min-width: 1280px) {
  .Navigation {
    height: 100%;
    width: 100%;
    text-align: center;
    .Navigation_content {
      margin: 0 auto;
      display: flex;
      height: 110px;
      line-height: 110px;
      .Navigation_content_img {
        width: 14%;
        height: 100%;
        .logo {
          img {
            width: 112px;
            height: 50px;
          }
        }
        .more {
          display: none;
        }
      }
      .Navigation_content_menu {
        text-align: center;
        flex: 1;
        font-size: 25px;
        display: block;
        a {
          color: rgb(0, 0, 0);
          padding: 0 2.83%;
        }
        .link:hover {
          color:#CC9966 !important;
        }
        .router-link-exact-active {
          color: #CC9966 !important;
        }
      }
    }
  }
}

@media (min-width: 752px) and (max-width: 1023px) {
  .Navigation {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    .Navigation_content {
      text-align: center;
      width: 100%;
      display: flex;
      height: 0.8rem;
      line-height: 0.8rem;
      .Navigation_content_img {
        width: 14%;
        height: 100%;
        .logo {
          img {
            width: 0.918rem;
            height: 0.4098rem;
          }
        }
        .more {
          display: none;
        }
      }
      .Navigation_content_menu {
        flex: 1;
        font-size: 0.2rem;
        display: inline-block;
        a {
          color: rgb(0, 0, 0);
          padding: 0 0.1rem;
        }
        a:last-child {
          padding: 0 0 0 0.1rem;
        }
        .link:hover {
          color: #359efe !important;
        }
        .router-link-exact-active {
          color: #359efe !important;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 750px) {
  .Navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    .Navigation_content {
      width: 100%;
      margin: 0 auto;
      background-color: #fff;
      height: 0.75rem;
      padding: 0.22rem 0.32rem 0.3rem;
      box-sizing: border-box;
      .Navigation_content_img {
        display: flex;
        justify-content: space-between;
        .logo {
          // flex: 1;
          img {
            width: 1.17rem;
            height: 0.35rem;
          }
        }
        .more {
          display: block;
          flex: none;
          width: 0.4rem;
          height: 0.4rem;
          img {
            width: 0.28rem;
            height: 0.28rem;
          }
        }
      }
      .van-popup {
        .Nav_content {
          margin-left: 0.4355rem;
          width: 5rem;
          .router-link-exact-active {
            color: #359efe !important;
          }
          div {
            width: 5.52rem;
            height: 0.83rem;
            line-height: 0.83rem;
            border-bottom: 1px solid #e9e9e9;

            img {
              width: 0.29rem;
              height: 0.29rem;
              margin: -0.08rem 0.24rem 0 0;
            }
          }
        }
      }

      .Navigation_content_menu {
        display: none;
      }
    }
  }
}
</style>